import * as THREE from 'three';

function generateRandomInt(min, max) {
  return Math.floor((Math.random() * (max*10 - min*10)) + min)/10;
}

function ImagePixel(path, w, h, ratio, path2, second) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", {
    willReadFrequently: true,
  });
  const width = w;
  const height = h;
  canvas.width = width;
  canvas.height = height;

  ctx.drawImage(path, 0, 0);
  const data = ctx.getImageData(0, 0, width, height).data;


  const canvas2 = document.createElement("canvas");
  const ctx2 = canvas2.getContext("2d", {
    willReadFrequently: true,
  });
  canvas2.width = width;
  canvas2.height = height;

  ctx2.drawImage(path2, 0, 0);
  const data2 = ctx2.getImageData(0, 0, width, height).data;
  const position = [];
  const color = [];
  const alpha = [];
  const size = [];
  const frequency = [];
  const direction = [];
  const power = [];

  for (let y = 0; y < height; y += ratio) {
    for (let x = 0; x < width; x += ratio) {
      const index = (y * width + x) * 4;
      const r = data[index] / 255;
      const g = data[index + 1] / 255;
      const b = data[index + 2] / 255;
      const a = data[index + 3] / 255;
      const r2 = data2[index] / 255;

      //spot画像0だったら
      if (r2 == 0 || (r == 0 && g == 0 && b == 0)) {
        continue;
      }

      const pX = x - width / 2;
      const pY = -(y - height / 2);
      const pZ = generateRandomInt(-20.0, 20.0);

      //sizeとPowerをr2と紐づける
      //255⇒1（max）
      const ratio = 2.425;
      if (second) {
        size.push(1.0 + (generateRandomInt(8.0, 10.0) * r2 * r2 * r2 * 3.4));
        power.push(10.0 + (generateRandomInt(100.0, 200.0)) * r2 * ratio);
        direction.push(generateRandomInt(0.0,0.5), generateRandomInt(0.0,0.5), pZ);
      } else {
        size.push(1.0 + (generateRandomInt(4.0, 6.0) * r2 * r2 * r2));
        power.push(10.0 + (generateRandomInt(100.0, 150.0)) * r2);
        direction.push(pX, pY, pZ);
      }
      frequency.push(5 * Math.random() + 0.5);
      position.push(pX, pY, pZ);
      color.push(r, g, b);
      alpha.push(a * r2);
    }
  }

  return { position, color, alpha, size, frequency, direction, power };
}

// 頂点シェーダー
const vertexSource = `
  attribute float alpha;
  attribute float size;
  attribute float power;
  attribute float frequency;
  attribute vec3 color;
  attribute vec3 direction;
  varying vec3 v_color;
  varying float v_alpha;
  varying float v_time;
  varying vec4 vMvPosition;
  uniform float time;
  uniform float fov;
  uniform float devicePixelRatio;
  vec2 randomVec2(vec2 st) {
    return vec2(
      fract(sin(dot(st, vec2(12.9898, 78.233)) * 43758.5453)),
      fract(sin(dot(st, vec2(12.9898, 78.233)) * 43758.5453))
    );
  }

  void main() {
    float M_2PI = 6.283185307179586;
    vec4 mvPosition = modelViewMatrix * vec4(position.xyz, 1.0);
    v_color = color;
    v_alpha = alpha;
    v_time = time;

    vec3 vertexDirection = vec3(normalize(direction.xy), direction.z * 0.25);

    vec3 finalPosition = position + vertexDirection * power * (fract(((frequency * time * 0.6)/M_2PI)));
    gl_Position = projectionMatrix * modelViewMatrix * vec4(finalPosition, 1.0 );

    gl_PointSize = (size * max(devicePixelRatio * 0.9, 1.0)) * (0.0 + abs(sin(frequency * time*0.5))) * 3.0 * ( 300.0 / length( mvPosition.xyz ));
  }
`;


const vertexSource2 = `
  attribute float alpha;
  attribute float size;
  attribute float power;
  attribute float frequency;
  attribute vec3 color;
  attribute vec3 direction;
  varying vec3 v_color;
  varying float v_alpha;
  varying float v_time;
  varying vec4 vMvPosition;
  uniform float time;
  uniform float fov;
  uniform float devicePixelRatio;
  vec2 randomVec2(vec2 st) {
    return vec2(
      fract(sin(dot(st, vec2(12.9898, 78.233)) * 43758.5453)),
      fract(sin(dot(st, vec2(12.9898, 78.233)) * 43758.5453))
    );
  }

  void main() {
    float M_2PI = 6.283185307179586;
    vec4 mvPosition = modelViewMatrix * vec4(position.xyz, 1.0);
    v_color = color;
    v_alpha = alpha;
    v_time = time;

    vec3 vertexDirection = vec3(normalize(vec2(0.5 - direction.x, -0.5 + direction.y)), 1.005);

    vec3 finalPosition = position + vertexDirection * power * (fract(((frequency * time * 0.6)/M_2PI)));
    gl_Position = projectionMatrix * modelViewMatrix * vec4(finalPosition, 1.0 );

    gl_PointSize = (size * max(devicePixelRatio * 0.75, 1.0)) * (0.0 + abs(sin(frequency * time*0.5))) * 3.0 * ( 300.0 / length( mvPosition.xyz ));
  }
`;



// ピクセルシェーダー
const fragmentSource = `
  varying vec3 v_color;
  varying float v_alpha;
  varying float v_p;
  varying float v_time;
  void main() {
      float M_PI = 3.141592653589793;
      float M_2PI = 6.283185307179586;
      vec2 tmpCoord = 0.5 * cos(M_2PI*gl_PointCoord+M_PI) + 0.5;
      float alpha = tmpCoord.x * tmpCoord.y;

      vec3 n;
      n.xy = gl_PointCoord * 2.0 - 1.0;  // 座標値を [0, 1] → [-1, 1] に変換する
      n.z = 1.0 - dot(n.xy, n.xy);  // 1 から x と y のそれぞれの二乗和を引く
      if (n.z < 0.0) discard;  // 結果が負ならフラグメントを捨てる

      // フラグメントの座標を取得
      vec2 uv = gl_PointCoord;

      // 時間に基づいてノイズを生成
      float noise = fract(sin(dot(uv, vec2(12.9898, 78.233)) + v_time * 0.5) * 43758.5453);

      // ランダムな閾値を設定
      float threshold = 0.98;

      // キラキラする条件を設定
      bool sparkle = noise > threshold;

      // キラキラする瞬間は白にする
      vec3 color = sparkle ? vec3(1.0) : vec3(v_color);
      float a = sparkle ? 1.0 : v_alpha * 0.5;
      gl_FragColor = vec4(vec3(color), a);
  }
`;

const fragmentSource2 = `
  varying vec3 v_color;
  varying float v_alpha;
  varying float v_p;
  varying float v_time;
  void main() {
      float M_PI = 3.141592653589793;
      float M_2PI = 6.283185307179586;
      vec2 tmpCoord = 0.5 * cos(M_2PI*gl_PointCoord+M_PI) + 0.5;
      float alpha = tmpCoord.x * tmpCoord.y;

      vec3 n;
      n.xy = gl_PointCoord * 2.0 - 1.0;  // 座標値を [0, 1] → [-1, 1] に変換する
      n.z = 1.0 - dot(n.xy, n.xy);  // 1 から x と y のそれぞれの二乗和を引く
      if (n.z < 0.0) discard;  // 結果が負ならフラグメントを捨てる

      // フラグメントの座標を取得
      vec2 uv = gl_PointCoord;
      gl_FragColor = vec4(vec3(v_color), v_alpha);
  }
`;

class Stage {
  constructor() {
    this.elem = document.getElementById("webgl");
    this.second = this.elem.parentElement.classList.contains('section-h1');
    this.renderParam = {
      clearColor: 0x000000,
      width: this.elem.offsetWidth,
      height: this.elem.offsetHeight
    };
    this.cameraParam = {
      fov: 50,
      near: 0.1,
      far: 1000,
      lookAt: new THREE.Vector3(0, 0, 0),
      x: 0,
      y: 0,
      z: 500,
    };

    this.scene = null;
    this.camera = null;
    this.renderer = null;
    this.isInitialized = false;
    this.isDev = false;
  }

  init() {
    this._setScene();
    this._setRender();
    this._setCamera();
    this._setDev();
  }

  _setScene() {
    this.scene = new THREE.Scene();
  }

  _setRender() {
    var _rendererParams = {
      alpha: true,
      depth: true,
      stencil: false,
      antialias: false,
      premultipliedAlpha: true,
      preserveDrawingBuffer: true,
      logarithmicDepthBuffer: false,
      autoClear: true,
      clearColor: 0xffffff,
      clearAlpha: 0,
      sortObjects: true,
      shadowMapEnabled: false,
      shadowMapType: THREE.PCFShadowMap,
      shadowMapCullFace: THREE.CullFaceFront,
      shadowMapDebug: false,
    };
    this.renderer = new THREE.WebGLRenderer(_rendererParams);
    this.renderer.setSize(this.renderParam.width, this.renderParam.height);
    // this.renderer.setClearColor(_rendererParams.clearColor);
    this.renderer.autoClear = _rendererParams.autoClear;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    const wrapper = document.querySelector("#webgl");
    wrapper.appendChild(this.renderer.domElement);
  }

  _setCamera() {
    // if (!this.isInitialized) {
    this.renderParam = {
      width: this.elem.offsetWidth,
      height: this.elem.offsetHeight
    };
    this.camera = new THREE.PerspectiveCamera(
      this.cameraParam.fov,
      this.renderParam.width / this.renderParam.height,
      this.cameraParam.near,
      this.cameraParam.far
    );

    this.cameraParam.fov = this.camera.fov * (this.renderParam.width / parseInt(this.renderer.domElement.style.width));
    this.camera.fov = Math.floor(this.cameraParam.fov);

    // 視野角をラジアンに変換
    const fov = this.cameraParam.fov;
    const fovRad = (fov / 2) * (Math.PI / 180);
    // カメラ距離を求める
    let distance = ((this.second ? 800 : 600) / 2) / Math.tan(fovRad);
    this.camera.position.z = distance;
    this.camera.lookAt(this.cameraParam.lookAt);

    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.renderParam.width, this.renderParam.height);
  }

  _setDev() {
  }

  _render() {
    this.renderer.render(this.scene, this.camera);
  }

  onResize() {
    this._setCamera();
  }

  onRaf() {
    this._render();
  }
}

class Particle {
  constructor(stage) {
    this.stage = stage;
    this.promiseList = []
    this.pathList = [
      '/images/top/hokkaido.png',
      '/images/common/second.png',
    ]
    this.textureList = [
      '/images/top/spot6.png',
      '/images/common/second_spot2.png',
    ]
    this.imageList = [];
    this.clock = new THREE.Clock();
  }

  init() {
    // this.pathList.forEach((image) => {
      this.promiseList.push(
        new Promise((resolve) => {
					const img = new Image();
					img.src = this.stage.second ? this.pathList[1] : this.pathList[0];
					img.crossOrigin = "anonymous";

					img.addEventListener('load', () => {
            const img2 = new Image();
            img2.src = this.stage.second ? this.textureList[1] : this.textureList[0];
            img2.crossOrigin = "anonymous";

            img2.addEventListener('load', () => {
              this.imageList.push(ImagePixel(img, img.width, img.height, this.stage.second ? 2.0 : 1.0, img2, this.stage.second));
              resolve();
            });
					});
        })
      )
    // })
    Promise.all(this.promiseList).then(() => {
      this._setMesh();
    });

  }

  _setMesh() {
    const geometry = new THREE.BufferGeometry();
    const position = new THREE.BufferAttribute(new Float32Array(this.imageList[0].position), 3);
    const direction = new THREE.BufferAttribute(new Float32Array(this.imageList[0].direction), 3);
    const color = new THREE.BufferAttribute(new Float32Array(this.imageList[0].color), 3);
    const alpha = new THREE.BufferAttribute(new Float32Array(this.imageList[0].alpha), 1);
    const size = new THREE.BufferAttribute(new Float32Array(this.imageList[0].size), 1);
    const power = new THREE.BufferAttribute(new Float32Array(this.imageList[0].power), 1);
    const frequency = new THREE.BufferAttribute(new Float32Array(this.imageList[0].frequency), 1);
    geometry.setAttribute('position', position);
    geometry.setAttribute('direction', direction);
    geometry.setAttribute('color', color);
    geometry.setAttribute('alpha', alpha);
    geometry.setAttribute('size', size);
    geometry.setAttribute('power', power);
    geometry.setAttribute('frequency', frequency);

    const material = new THREE.ShaderMaterial({
      uniforms: {
        time: {
            type: 'f',
            value: 0.0
        },
        fov: {
            type: 'f',
            value: 50.0 / this.stage.camera.fov
        },
        devicePixelRatio: {
            type: 'f',
            value: window.devicePixelRatio
        },
      },
      vertexShader: this.stage.second ? vertexSource2 : vertexSource,
      fragmentShader: this.stage.second ? fragmentSource2 : fragmentSource,
      transparent: true,
      blending: THREE.NormalBlending,
      depthWrite: false,
      depthTest: false,
    });
    this.mesh = new THREE.Points(geometry, material);
    this.stage.scene.add(this.mesh);
  }

  _render() {
    if (!this.mesh) return;
    const t = this.clock.getElapsedTime();
    this.mesh.material.uniforms.time.value = t;
    this.mesh.material.uniforms.fov.value = 50.0 / this.stage.camera.fov;
  }

  onResize() {
    //
  }

  onRaf() {
    this._render();
  }
}

class Webgl {
	constructor() {
		const stage = new Stage();
		stage.init();

		const particle = new Particle(stage);
		particle.init();

		window.addEventListener("resize", () => {
			stage.onResize();
			particle.onResize();
		});

		const _raf = () => {
			window.requestAnimationFrame(() => {
				_raf();

				stage.onRaf();
				particle.onRaf();
			});
		};
		_raf();
	}
}

export default Webgl;