let scrollElm = (function () {
  if (document.scrollingElement) return document.scrollingElement;
  if (navigator.userAgent.indexOf("WebKit") != -1) return "body";
  return document.documentElement;
})();

let header = $(".header-fixed");
let scrollBtn = $(".top-of-page");
let subFooter = $(".sub-footer-bg-img ");
let hamburgerBtn = $(".header-hamburger");
let closeBtn = $(".header-close");
let imgWindow = $(".img-window-area");
let subFooterBgImg = $(".sub-footer-bg-img")
let scrollVal;
let isScrolling = 0 ;
let isScrollingTime;
let windowHeight = 0;
let windowWidth = 0;
let contentHeight = 0;
let headerHeight = 0;
let footerHeight = 0;
let breadcrumbHeight = 0;
let h1Hegiht = 0;
let iWContentHeight = 0;
let iWContentChildHeight = 0;
let iWimageHeight = 0;
let iWimageWidth = 0;


$(window).on("scroll touchmove", scrollCheck);
$(window).on("load", function() {
  scrollCheck();
  $("body").addClass("is-start");
  if ($(".sub-mv-inner").length) {
    $(".sub-mv-inner .img img").addClass("is-active");
  }
  if ($(".slide").length) {
    mvShuffle();
    setTimeout( function() {
      $(".slide").find(".img").eq(0).addClass("is-init");
      mvScroll();
    }, 100 );
  }
});

function scrollCheck() {
  scrollVal = $(window).scrollTop();
  windowHeight = window.innerHeight ? window.innerHeight : $(window).height();
  windowWidth = window.innerWidth ? window.innerWidth : $(window).width();
  contentHeight = $("body").height();
  headerHeight = $(".header-fixed").height();
  footerHeight = $(".footer").height();
  breadcrumbHeight = $(".section.type-breadcrumb").height();
  iWContentHeight = $(".img-window-content").height();
  iWContentChildHeight = $(".img-window-content").children().height()
  iWimageHeight = $(".img-window-images").height()
  iWimageWidth = $(".img-window-images").width()
  h1Hegiht = $(".section-h1").height();
  if (scrollVal > 100) {
    scrollBtn.addClass("is-show");
    header.addClass("is-notop");
  } else if (scrollVal < 100) {
    scrollBtn.removeClass("is-show");
    header.removeClass("is-notop");
  }
  if (scrollVal < 2500) {
    subFooterBgImg.addClass("is-top");
  } else  {
    subFooterBgImg.removeClass("is-top")
  }
  if (iWimageHeight > iWContentChildHeight) {
    imgWindow.addClass("is-ai-s");
    imgWindow.removeClass("is-fixed");
    imgWindow.removeClass("is-bottom");
  } else {
    imgWindow.removeClass("is-ai-s");
    if (scrollVal > h1Hegiht - headerHeight ) {
      imgWindow.addClass("is-fixed");
    } else {
      imgWindow.removeClass("is-fixed");
    }
    if (imgWindow.hasClass("is-fixed") && scrollVal > h1Hegiht + iWContentHeight - iWimageHeight - headerHeight && iWContentHeight > iWimageHeight) {
      imgWindow.addClass("is-bottom");
    } else {
      imgWindow.removeClass("is-bottom");
    }
    if (windowWidth > 768 && imgWindow.hasClass("is-fixed") && !(imgWindow.hasClass("is-bottom"))) {
      $(".img-window-content").css('margin-left',iWimageWidth);
    }else {
      $(".img-window-content").css('margin-left',0);
    }
  }

  if (scrollVal + windowHeight > contentHeight - footerHeight + 100) {
    scrollBtn.addClass("is-bottom");
  } else if (scrollVal + windowHeight < contentHeight - footerHeight + 100) {
    scrollBtn.removeClass("is-bottom");
  }
  if (scrollVal > windowHeight * 0.8) {
    $("body").addClass("is-scroll-oneScreen");
  } else {
    $("body").removeClass("is-scroll-oneScreen");
  }
  // if (scrollVal + windowHeight > contentHeight - footerHeight - breadcrumbHeight) {
  //   subFooter.addClass("is-bottom");
  // } else if (scrollVal + windowHeight < contentHeight - footerHeight - breadcrumbHeight) {
  //   subFooter.removeClass("is-bottom");
  // }
}

window.addEventListener("scroll", function () {
	isScrolling = 1 ;
	clearTimeout( isScrollingTime ) ;
	isScrollingTime = setTimeout( function () {
		isScrolling = 0 ;
	}, 300 ) ;
} ) ;

scrollBtn.on("click",function () {
  if (isScrolling == 0) {
    window.scroll({ top: 0, behavior: "smooth" });
  }
  return false;
});

hamburgerBtn.on("click",function () {
  const target = $(".header-toggle");
  if (!target.is(":animated")) {
    target.slideToggle(250);
    $("body").toggleClass("is-header-open");
    hamburgerBtn.find(".text").text($("body").hasClass("is-header-open") ? "CLOSE" : "MENU");
    if ($("body").hasClass("is-header-open")) {
      scrollSet.stopScroll();
    } else {
      scrollSet.removeScroll();
    }
  }
});

closeBtn.on("click",function () {
  const target = $(".header-toggle");
  if (!target.is(":animated")) {
    target.slideUp(250);
    $("body").removeClass("is-header-open");
    hamburgerBtn.find(".text").text("MENU");
    scrollSet.removeScroll();
  }
});

let scrollSet = {
  scrollTop: 0,
  scrollTop2: 0,
  scrollbarW : 0,
  stopScroll: function() {
    let headerHeight = $(".header-fixed").height();
    scrollSet.scrollbarW = window.innerWidth - document.body.clientWidth;
    scrollSet.scrollTop2 = scrollVal;
    $("body").css({
      "padding-right": scrollSet.scrollbarW + "px"
    }).addClass("is-scrollStop");
    $(scrollElm).css({
      'height': '100vh',
      'height': 'calc(var(--vh, 1vh) * 100)',
      'left': '0',
      'overflow': 'hidden',
      'position': 'fixed',
      'top': (scrollSet.scrollTop2 * -1) + headerHeight + "px",
      'width': '100vw',
    });
  },
  removeScroll: function() {
    $("body").css({
      "padding-right": ""
    }).removeClass("is-scrollStop");
    $(scrollElm).css({
      'height': '',
      'left': '',
      'overflow': '',
      'position': '',
      'top': '',
      'width': '',
      'min-width': ''
    });
    $(scrollElm).scrollTop(scrollSet.scrollTop2);
  }
}

// breakpoint
const mediaQueryList = window.matchMedia('(min-width: 769px)');
mediaQueryList.addEventListener("change", listener);
listener(mediaQueryList);
function listener(event) {
  if (event.matches) {
  // PC
    $("body").removeClass("is-header-open");
    hamburgerBtn.find(".text").text("MENU");
    scrollSet.removeScroll();
  } else {
  // SP
  }
}

function mvScroll() {
  let $slider = $(".slide");
  let counter = 0;
  let max = $slider.find(".img").length;
  $slider.find(".img").eq(0).addClass("is-current");
  setInterval(function() {
    $slider.find(".img").eq(0).removeClass("is-init");
    counter++;
    if (counter >= max) {
      counter = 0;
    }
    $slider.find(".is-current.is-fadeout").removeClass("is-fadeout").removeClass("is-current");
    $slider.find(".is-current").addClass("is-fadeout");
    $slider.find(".img").eq(counter).addClass("is-current")
  }, 8000);
}

function mvShuffle() {
  if (!$(".slide").hasClass("top")){
    let $slider = $(".slide").find("> *");
    let total = $slider.length;
    $slider.each(function() {
      $slider.eq(Math.floor(Math.random() * total)).prependTo($(".slide"));
    });
  }
}

//navi current
let nowUrl = location.pathname;
if (nowUrl.indexOf('index.html') > 0) {
  nowUrl = nowUrl.replace("index.html", "");
}
if (nowUrl.includes("/news/")) {
  nowUrl = "/news/";
}

let gNavi = $(".header-toggle .header-navi a");
gNavi.each(function() {
  let naviUrl = $(this).attr('href');
  if (naviUrl == nowUrl) {
    $(this).addClass("is-current");
  }else {
    $(this).removeClass("is-current");
  }
});

$(document).on("click",".form-consent-checkbox #conf_policy",function(){
  if (this.checked) {
    $(".form-consent-submit").removeClass("is-disabled");
  } else {
    $(".form-consent-submit").addClass("is-disabled");
  }
});

import Webgl from "./modules/Webgl.js";

window.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("webgl")) {
    new Webgl();
  }
});